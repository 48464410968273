.link {
  &:global(.arrow-block) {
    @apply bg-summer-green-25 rounded relative font-serif font-bold text-base py-2 px-4.5 min-h-[64px] flex items-center;

    & span {
      @apply block max-w-[65%] md:max-w-[80%] leading-[normal] line-clamp-2;
    }

    & :global(.arrow) {
      @apply absolute aspect-1 h-full bg-summer-green-100 top-0 right-0 rounded-tr rounded-br;
      @apply flex justify-center items-center;
    }
  }

  &:global(.text-w-icon) {
    @apply text-base font-bold underline;

    & :global(.open-in-new) {
      @apply inline-block ml-2.5 w-6 h-6 align-middle;
    }
  }

  &:global(.green-text) {
    @apply text-lg font-bold underline text-green-100;

    & :global(.open-in-new) {
      @apply inline-block ml-2.5 w-6 h-6 align-middle;
    }
  }
}
